import {useCallback, useEffect, useState} from 'react';

import {
  CalculationsTransformationDatalakeElementItem,
  CalculationsTransformationDatalakeElementPayload,
  DatalakeCalculationOperator,
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
  DatalakeOutputColumnType,
} from '@shared/dynamo_model';
import {generateLayoutForNonDataSource} from '@shared/frontends/datalake_element_layout';
import {uidUnsafe} from '@shared/lib/rand';
import {iterNumberEnum} from '@shared/lib/type_utils';

import {Button} from '@shared-frontend/components/core/button';
import {CenteredLine} from '@shared-frontend/components/core/fragments';
import {Input} from '@shared-frontend/components/core/input';
import {Select} from '@shared-frontend/components/core/select';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {Custom} from '@shared-frontend/lib/react';

import {
  getElements,
  pushElementsSnaphsot,
  useDatalakeId,
} from '@src/components/demo/datalake/demo_store';
import {Form, FormPropsBase, Label} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {
  columnIs,
  TableAndFieldSelector,
  TableFieldChangeHandler,
} from '@src/components/demo/datalake/toolbox/forms/common/table_and_field_selector';
import {useDeleteElementHandler} from '@src/components/demo/datalake/toolbox/forms/use_delete_element_handler';
import {useOnescaleSession} from '@src/lib/stores';

interface CalculationsTransformationFormProps extends FormPropsBase {
  update?: CalculationsTransformationDatalakeElementItem;
}

const DATALAKE_CALCULATION_OPERATOR_TO_STRING: Record<DatalakeCalculationOperator, string> = {
  [DatalakeCalculationOperator.Multiply]: 'Multiplication',
};

export const CalculationsTransformationForm: Custom<
  CalculationsTransformationFormProps,
  'div'
> = props => {
  const {update, onDone, ...rest} = props;
  const datalakeId = useDatalakeId();
  const session = useOnescaleSession();

  const [operator, setOperator] = useState(update?.params.operator);
  const [outputName, setOutputName] = useState(update?.params.outputName);
  const [{tableId, firstColumnIndex, secondColumnIndex}, setSource] = useState<{
    tableId?: DatalakeElementItemId;
    firstColumnIndex?: number;
    secondColumnIndex?: number;
  }>({
    tableId: update?.params.source,
    firstColumnIndex: update?.params.firstColumnIndex,
    secondColumnIndex: update?.params.secondColumnIndex,
  });

  useEffect(() => {
    setOperator(update?.params.operator);
    setOutputName(update?.params.outputName);
    setSource({
      tableId: update?.params.source,
      firstColumnIndex: update?.params.firstColumnIndex,
      secondColumnIndex: update?.params.secondColumnIndex,
    });
  }, [update]);

  const handleChange = useCallback<TableFieldChangeHandler>(
    (tableId, [firstColumnIndex, secondColumnIndex]) =>
      setSource({tableId, firstColumnIndex, secondColumnIndex}),
    []
  );

  const handleClick = useCallback(() => {
    if (
      update !== undefined ||
      outputName === undefined ||
      outputName === '' ||
      operator === undefined ||
      tableId === undefined ||
      firstColumnIndex === undefined ||
      secondColumnIndex === undefined ||
      !session
    ) {
      return;
    }
    const {items} = getElements();
    const table = items[tableId];
    if (table === undefined) {
      return;
    }
    if (table.outputs.columns.find(o => o.name === outputName) !== undefined) {
      return; // Column with this name already exists
    }
    const newElementId = uidUnsafe() as DatalakeElementItemId;
    const params: CalculationsTransformationDatalakeElementPayload = {
      group: DatalakeElementTypeGroup.Transformation,
      outputName,
      operator,
      source: tableId,
      firstColumnIndex,
      secondColumnIndex,
    };
    const calculationTransformation: CalculationsTransformationDatalakeElementItem = {
      elementId: newElementId,
      datalakeId,
      accountId: session.userData.accountId,
      type: DatalakeElementType.CalculationsTransformation,
      params,
      outputs: {
        columns: [
          ...table.outputs.columns,
          {name: outputName, type: DatalakeOutputColumnType.Number},
        ],
      },
      layout: generateLayoutForNonDataSource(params, items),
    };
    pushElementsSnaphsot({...items, [newElementId]: calculationTransformation})
      .catch(notifyError)
      .finally(onDone);
  }, [
    datalakeId,
    firstColumnIndex,
    onDone,
    operator,
    outputName,
    secondColumnIndex,
    session,
    tableId,
    update,
  ]);

  const handleDelete = useDeleteElementHandler(update?.elementId);

  return (
    <Form {...rest}>
      <Label>Opération</Label>
      <Select
        placeholder="--- Sélectionnez une opération ---"
        values={iterNumberEnum(DatalakeCalculationOperator).map(v => ({
          value: v,
          label: DATALAKE_CALCULATION_OPERATOR_TO_STRING[v],
        }))}
        value={operator}
        syncState={setOperator}
      />
      <Spacing height={8} />
      <TableAndFieldSelector
        tableId={tableId}
        tableLabel="Table à sélectionner"
        fields={[
          {label: 'Premier champ', index: firstColumnIndex},
          {label: 'Second champ', index: secondColumnIndex},
        ]}
        filterColumn={columnIs(DatalakeOutputColumnType.Number)}
        onChange={handleChange}
      />
      <Spacing height={8} />
      <Label>Champs de résultat</Label>
      <Input theme="demo" value={outputName} syncState={setOutputName} autoFocus />
      <Spacing height={8} />
      <CenteredLine>
        {update === undefined ? (
          <Button onClick={handleClick}>VALIDER</Button>
        ) : (
          <Button onClickAsync={handleDelete}>DELETE</Button>
        )}
      </CenteredLine>
    </Form>
  );
};
CalculationsTransformationForm.displayName = 'CalculationsTransformationForm';
