import {FC} from 'react';
import {Routes} from 'react-router-dom';

import {RouteOpts, sessionRequired, useRoute} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/core/use_shared_routes';

import {GlobalStyle} from '@src/components/global_styles';
import {RoutesTransition} from '@src/components/routes_transition';
import {Demo} from '@src/views/demo';
import {Home} from '@src/views/home';
import {NotFound} from '@src/views/not_found';

export const App: FC = () => {
  const seo = {
    title: 'OneScale | Créez votre propre Data Lake',
    description: 'Créez votre propre Data Lake. Simple. Sécurisé. Performant. Sans engagement.',
  };

  const publicRoutesOpts: RouteOpts = {seo};
  const privateRoutesOpts: RouteOpts = {seo, isDisabledHandler: sessionRequired(NotFound)};

  return (
    <>
      <GlobalStyle />
      <RoutesTransition>
        <Routes>
          {useSharedRoutes({seo}, {NotFoundComponent: NotFound})}
          {useRoute('/', Home, publicRoutesOpts)}
          {useRoute('/demo/:page', Demo, privateRoutesOpts)}
          {useRoute('/demo', Demo, privateRoutesOpts)}
          {useRoute('/*', NotFound, privateRoutesOpts)}
        </Routes>
      </RoutesTransition>
    </>
  );
};
App.displayName = 'App';
