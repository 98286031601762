import {FC, useCallback} from 'react';

import {DatalakeElementItemId, DatalakeElementTypeGroup} from '@shared/dynamo_model';

import {Select} from '@shared-frontend/components/core/select';

import {useElements} from '@src/components/demo/datalake/demo_store';
import {elementItemToString} from '@src/components/demo/datalake/toolbox/element_type';
import {Label} from '@src/components/demo/datalake/toolbox/forms/common/form';

export type TableChangeHandler = (tableId: DatalakeElementItemId | undefined) => void;

interface TableSelectorProps {
  tableLabel: string;
  tableId?: DatalakeElementItemId;
  onChange: TableChangeHandler;
}

export const TableSelector: FC<TableSelectorProps> = props => {
  const {tableLabel, tableId, onChange} = props;
  const items = useElements();
  const elements = Object.values(items).filter(
    el => el.params.group !== DatalakeElementTypeGroup.Output
  );

  const onSourceIdChange = useCallback(
    (newSourceId: DatalakeElementItemId | undefined) => {
      onChange(newSourceId);
    },
    [onChange]
  );

  return (
    <>
      <Label>{tableLabel}</Label>
      <Select<DatalakeElementItemId | undefined>
        placeholder={'Sélectionnez une table'}
        values={elements.map(el => ({value: el.elementId, label: elementItemToString(el)}))}
        value={tableId}
        syncState={onSourceIdChange}
      />
    </>
  );
};
TableSelector.displayName = 'TableSelector';
