import {FC, ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {Transition, TransitionGroup} from 'react-transition-group';

import {PageWrapper} from '@src/components/page_fragments';

export const RoutesTransition: FC<{children: ReactNode}> = props => {
  const {children} = props;
  const location = useLocation();
  if (location.pathname === '/') {
    return <PageWrapper>{children}</PageWrapper>;
  }
  return (
    <TransitionGroup component={PageWrapper}>
      <Transition
        key={location.key}
        onEnter={handleTransitionEnter}
        onExit={handleTransitionExit}
        timeout={0}
      >
        {() => <>{children}</>}
      </Transition>
    </TransitionGroup>
  );
};
RoutesTransition.displayName = 'RoutesTransition';

function handleTransitionEnter(node: HTMLElement | null /*, isAppearing: boolean*/): void {
  // eslint-disable-next-line no-null/no-null
  if (node === null) {
    return;
  }
  node.style.opacity = '0';
  node.style.filter = `alpha(opacity=0)`;
  let opacity = 0.1;
  const interval = setInterval(() => {
    if (opacity >= 1) {
      clearInterval(interval);
    }
    node.style.opacity = opacity.toString();
    node.style.filter = `alpha(opacity=${opacity * 100})`;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    opacity += opacity * 0.1;
  }, 10);
}

function handleTransitionExit(node: HTMLElement | null): void {
  // eslint-disable-next-line no-null/no-null
  if (node === null) {
    return;
  }
  node.style.opacity = '0';
  node.style.filter = `alpha(opacity=0)`;
}
