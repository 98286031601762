import {Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useState} from 'react';
import styled from 'styled-components';

import {
  DatalakeElementType,
  DatalakeElementTypeByGroup,
  DatalakeElementTypeGroup,
} from '@shared/dynamo_model';
import {arrayJoin} from '@shared/lib/array_utils';
import {capitalize} from '@shared/lib/format_utils';
import {iterNumberEnum} from '@shared/lib/type_utils';
import {dataLakeElementTypeGroupToString} from '@shared/model/datalake';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';

import {NewElementTile} from '@src/components/demo/datalake/toolbox/new_element_tile';
import {ToolboxTitle} from '@src/components/demo/datalake/toolbox/toolbox_title';
import {Colors} from '@src/components/theme_base';

interface NewElementPickerProps {
  selectedElement: DatalakeElementType | undefined;
  syncState: Dispatch<SetStateAction<DatalakeElementType | undefined>>;
}

export const NewElementPicker: FC<NewElementPickerProps> = props => {
  const {selectedElement, syncState} = props;
  const [newElementOpen, setNewElementOpen] = useState(false);

  const toggleNewElement = (): void => {
    setTabOpen(undefined);
    syncState(undefined);
    setNewElementOpen(!newElementOpen);
  };
  const [tabOpen, setTabOpen] = useState<DatalakeElementTypeGroup | undefined>();

  const handleCategoryTitleClick = useCallback<MouseEventHandler>(
    evt => {
      const tabName = parseFloat(
        evt.currentTarget.getAttribute('data-group') ?? ''
      ) as DatalakeElementTypeGroup;
      setTabOpen(tabOpen === tabName ? undefined : tabName);
    },
    [tabOpen]
  );

  const handleElementClick = useCallback<MouseEventHandler>(
    evt => {
      const elementType = parseFloat(
        evt.currentTarget.getAttribute('data-element-type') ?? ''
      ) as DatalakeElementType;
      syncState(elementType);
    },
    [syncState]
  );

  return (
    <>
      <ToolboxTitle
        label="NOUVEL ÉLÉMENT"
        iconName={newElementOpen ? 'ChevronDown' : 'ChevronRight'}
        iconSize={9}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => toggleNewElement()}
      />
      <Categories hidden={!newElementOpen}>
        {arrayJoin(
          iterNumberEnum(DatalakeElementTypeGroup).map(group => (
            <Category key={group}>
              <CategoryTitle data-group={group} onClick={handleCategoryTitleClick}>
                <Title>{capitalize(dataLakeElementTypeGroupToString(group))}</Title>
                <Icon hidden={tabOpen === group}>
                  <SvgIcon name="Plus" size={10} color={Colors.White} />
                </Icon>
                <Icon hidden={tabOpen !== group}>
                  <SvgIcon name="Minus" size={10} color={Colors.White} />
                </Icon>
              </CategoryTitle>
              <Elements hidden={tabOpen !== group}>
                {DatalakeElementTypeByGroup[group].map(type => (
                  <NewElementTile
                    key={type}
                    selectedElement={selectedElement}
                    onClick={handleElementClick}
                    data-element-type={type}
                    type={type}
                  />
                ))}
              </Elements>
            </Category>
          )),
          i => (
            <Separator key={i} />
          )
        )}
      </Categories>
    </>
  );
};
NewElementPicker.displayName = 'NewElementPicker';

const Elements = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const Title = styled.div`
  flex-grow: 1;
  line-height: 18px;
`;

const Icon = styled.div`
  flex-shrink: 1;
  margin: auto;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.Gray};
`;

const CategoryTitle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 10px;

  &:hover {
    background-color: ${Colors.DarkBlue3};
  }
`;

const Categories = styled.div``;

const Category = styled.div`
  width: 100%;
`;
