import {FC, useCallback} from 'react';
import styled from 'styled-components';

import {DatalakeElementType} from '@shared/dynamo_model';

import {setSelectedElement, useSelectedElement} from '@src/components/demo/datalake/demo_store';
import {
  elementTypeToIcon,
  elementTypeToLabel,
} from '@src/components/demo/datalake/toolbox/element_type';
import {CalculationsTransformationForm} from '@src/components/demo/datalake/toolbox/forms/calculations_transformation_form';
import {Form} from '@src/components/demo/datalake/toolbox/forms/common/form';
import {ExtractStringTransformationForm} from '@src/components/demo/datalake/toolbox/forms/extract_string_transformation_form';
import {FileDropDataSourceForm} from '@src/components/demo/datalake/toolbox/forms/file_drop_data_source_form';
import {GraphOutputForm} from '@src/components/demo/datalake/toolbox/forms/graph_output_form';
import {IpGeolocTransformationForm} from '@src/components/demo/datalake/toolbox/forms/ip_geoloc_transformation_form';
import {LabelTransformationForm} from '@src/components/demo/datalake/toolbox/forms/label_transformation_form';
import {QontoDataSourceForm} from '@src/components/demo/datalake/toolbox/forms/qonto_data_source_form';
import {RestApiOutputForm} from '@src/components/demo/datalake/toolbox/forms/rest_api_output_form';
import {SumsAggregationForm} from '@src/components/demo/datalake/toolbox/forms/sums_aggregation_form';
import {WebEventsDataSourceForm} from '@src/components/demo/datalake/toolbox/forms/web_events_data_source_form';
import {ToolboxTitle} from '@src/components/demo/datalake/toolbox/toolbox_title';

export const CurrentSelectionForm: FC = () => {
  const selectedElement = useSelectedElement();
  const handleDone = useCallback(() => setSelectedElement(undefined), []);

  if (selectedElement === undefined) {
    return <></>;
  }

  const {type} = selectedElement;

  return (
    <>
      <ToolboxTitle
        label={elementTypeToLabel(type)}
        iconName={elementTypeToIcon(type)}
        iconSize={12}
      />
      <Categories>
        <Form>
          {type === DatalakeElementType.WebEventsDataSource ? (
            <WebEventsDataSourceForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.FileDropDataSource ? (
            <FileDropDataSourceForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.QontoDataSource ? (
            <QontoDataSourceForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.IpGeolocTransformation ? (
            <IpGeolocTransformationForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.CalculationsTransformation ? (
            <CalculationsTransformationForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.ExtractStringTransformation ? (
            <ExtractStringTransformationForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.LabelTransformation ? (
            <LabelTransformationForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.SumsAggregation ? (
            <SumsAggregationForm update={selectedElement} onDone={handleDone} />
          ) : type === DatalakeElementType.GraphOutput ? (
            <GraphOutputForm update={selectedElement} onDone={handleDone} />
          ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          type === DatalakeElementType.RestApiOutput ? (
            <RestApiOutputForm update={selectedElement} onDone={handleDone} />
          ) : (
            <></>
          )}
        </Form>
      </Categories>
    </>
  );
};
CurrentSelectionForm.displayName = 'CurrentSelectionForm';

const Categories = styled.div``;
