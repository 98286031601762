import {FC, Fragment, JSX, useCallback, useMemo} from 'react';

import {
  DatalakeElementItem,
  DatalakeElementItemId,
  DatalakeElementType,
  DatalakeElementTypeGroup,
} from '@shared/dynamo_model';
import {neverHappens} from '@shared/lib/type_utils';
import {DatalakeElementItems} from '@shared/model/datalake';

import {notifyError} from '@shared-frontend/lib/notification';

import {
  pushElementsSnaphsot,
  setSelectedElement,
  useDataLakeSettings,
  useElementsHistory,
} from '@src/components/demo/datalake/demo_store';
import {Draggable} from '@src/components/demo/datalake/draggable';
import {PathMode, TileConnection} from '@src/components/demo/datalake/tile_connection';
import {CalculationsTransformationTile} from '@src/components/demo/datalake/tiles/calculations_transformation_tile';
import {ExtractStringTransformationTile} from '@src/components/demo/datalake/tiles/extract_string_transformation_tile';
import {FileDropDataSourceTile} from '@src/components/demo/datalake/tiles/file_drop_data_source_tile';
import {GraphOutputTile} from '@src/components/demo/datalake/tiles/graph_output_tile';
import {IpGeolocTransformationTile} from '@src/components/demo/datalake/tiles/ip_geoloc_transformation_tile';
import {LabelTransformationTile} from '@src/components/demo/datalake/tiles/label_transformation_tile';
import {QontoDataSourceTile} from '@src/components/demo/datalake/tiles/qonto_data_source_tile';
import {RestApiOutputTile} from '@src/components/demo/datalake/tiles/rest_api_output_tile';
import {SumsAggregationTile} from '@src/components/demo/datalake/tiles/sums_aggregation_tile';
import {WebEventsDataSourceTile} from '@src/components/demo/datalake/tiles/web_events_data_source_tile';

function renderElementTile(element: DatalakeElementItem): JSX.Element {
  const {type} = element;
  if (type === DatalakeElementType.WebEventsDataSource) {
    return <WebEventsDataSourceTile element={element} />;
  } else if (type === DatalakeElementType.FileDropDataSource) {
    return <FileDropDataSourceTile element={element} />;
  } else if (type === DatalakeElementType.QontoDataSource) {
    return <QontoDataSourceTile element={element} />;
  } else if (type === DatalakeElementType.IpGeolocTransformation) {
    return <IpGeolocTransformationTile element={element} />;
  } else if (type === DatalakeElementType.CalculationsTransformation) {
    return <CalculationsTransformationTile element={element} />;
  } else if (type === DatalakeElementType.ExtractStringTransformation) {
    return <ExtractStringTransformationTile element={element} />;
  } else if (type === DatalakeElementType.LabelTransformation) {
    return <LabelTransformationTile element={element} />;
  } else if (type === DatalakeElementType.SumsAggregation) {
    return <SumsAggregationTile element={element} />;
  } else if (type === DatalakeElementType.GraphOutput) {
    return <GraphOutputTile element={element} />;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (type === DatalakeElementType.RestApiOutput) {
    return <RestApiOutputTile element={element} />;
  }
  neverHappens(element, `Unknown DatalakeElementItem type "${type}"`);
}

function generateTileConnections(items: DatalakeElementItem[]): TileItemConnection[] {
  const connections: TileItemConnection[] = [];
  for (const {params, type, elementId} of items) {
    if (params.group === DatalakeElementTypeGroup.DataSource) {
      continue;
    } else if (type === DatalakeElementType.IpGeolocTransformation) {
      connections.push({from: params.source, to: elementId});
    } else if (type === DatalakeElementType.CalculationsTransformation) {
      connections.push({from: params.source, to: elementId});
    } else if (type === DatalakeElementType.ExtractStringTransformation) {
      connections.push({from: params.source, to: elementId});
    } else if (type === DatalakeElementType.LabelTransformation) {
      connections.push({from: params.source, to: elementId});
    } else if (type === DatalakeElementType.SumsAggregation) {
      connections.push({from: params.source, to: elementId});
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (params.group === DatalakeElementTypeGroup.Output) {
      connections.push({from: params.source, to: elementId});
    } else {
      neverHappens(params);
    }
  }
  return connections;
}

interface TileItemConnection {
  from: DatalakeElementItemId;
  to: DatalakeElementItemId;
}

interface DemoTilesProps {
  elements: DatalakeElementItems;
}

export const DemoTiles: FC<DemoTilesProps> = ({elements}) => {
  const dataLakeSettings = useDataLakeSettings();
  const {manualTrigger} = useElementsHistory();
  const connections = useMemo(() => generateTileConnections(Object.values(elements)), [elements]);

  const handlePosChange = useCallback(
    (x: number, y: number, id: string, initial: boolean): void => {
      const item = elements[id as DatalakeElementItemId];
      if (!item) {
        return;
      }
      pushElementsSnaphsot(
        {
          ...elements,
          [id as DatalakeElementItemId]: {...item, layout: {...item.layout, x, y}},
        },
        {replaceHistory: !initial, throttleUpdates: true}
      ).catch(notifyError);
    },
    [elements]
  );

  const handleItemClick = useCallback(
    (id: string): void => {
      const item = elements[id as DatalakeElementItemId];
      if (!item) {
        return;
      }
      setSelectedElement(item.elementId);
    },
    [elements]
  );

  return (
    <>
      {Object.entries(elements).map(([id, item]) =>
        dataLakeSettings.shown[item.params.group] ? (
          <Draggable
            itemId={id}
            initialX={item.layout.x}
            initialY={item.layout.y}
            reset={manualTrigger}
            onPosChange={handlePosChange}
            onItemClick={handleItemClick}
            key={id}
            // eslint-disable-next-line react/forbid-component-props
            style={{width: item.layout.width, height: item.layout.height}}
          >
            {renderElementTile(item)}
          </Draggable>
        ) : (
          <Fragment key={id} />
        )
      )}
      {connections.map(({from, to}) => {
        const fromItem = elements[from];
        const toItem = elements[to];
        if (
          fromItem &&
          toItem &&
          dataLakeSettings.shown[fromItem.params.group] &&
          dataLakeSettings.shown[toItem.params.group]
        ) {
          return (
            <TileConnection
              key={`${from}-${to}`}
              pathMode={PathMode.CubicSoft}
              width={toItem.layout.x - (fromItem.layout.x + fromItem.layout.width)}
              height={
                toItem.layout.y +
                toItem.layout.height / 2 -
                (fromItem.layout.y + fromItem.layout.height / 2)
              }
              // eslint-disable-next-line react/forbid-component-props
              style={{
                top: fromItem.layout.y + fromItem.layout.height / 2,
                left: fromItem.layout.x + fromItem.layout.width,
              }}
            />
          );
        }
        return <Fragment key={`${from}-${to}`} />;
      })}
    </>
  );
};
DemoTiles.displayName = 'DemoTiles';
