import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {DatalakeElementType} from '@shared/dynamo_model';

import {Column} from '@shared-frontend/components/core/fragments';

import {CurrentSelectionForm} from '@src/components/demo/datalake/toolbox/current_selection_form';
import {DataLakeSettingsForm} from '@src/components/demo/datalake/toolbox/data_lake_settings_form';
import {NewElementForm} from '@src/components/demo/datalake/toolbox/new_element_form';
import {NewElementPicker} from '@src/components/demo/datalake/toolbox/new_element_picker';
import {ToolboxFooter} from '@src/components/demo/datalake/toolbox/toolbox_footer';
import {Colors} from '@src/components/theme_base';

export const Toolbox: FC = () => {
  const [selectedElement, setSelectedElement] = useState<DatalakeElementType | undefined>();
  const handleDone = useCallback(() => setSelectedElement(undefined), []);

  return (
    <Container>
      <Content>
        <CurrentSelectionForm />
        <DataLakeSettingsForm />
        <NewElementPicker selectedElement={selectedElement} syncState={setSelectedElement} />
        {selectedElement === undefined ? (
          <></>
        ) : (
          <NewElementForm selectedElement={selectedElement} onDone={handleDone} />
        )}
      </Content>
      <NoShrinkToolboxFooter />
    </Container>
  );
};
Toolbox.displayName = 'Toolbox';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: ${Colors.DarkBlue};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  color: ${Colors.White};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  overflow-y: auto;
  border-left: 1px solid ${Colors.DarkBlue3};
`;

const Content = styled(Column)``;

const NoShrinkToolboxFooter = styled(ToolboxFooter)`
  flex-shrink: 0;
`;
