import {FC, Fragment, useCallback, useEffect, useState} from 'react';

import {
  DatalakeElementItemId,
  DatalakeElementTypeGroup,
  DatalakeOutputColumnType,
} from '@shared/dynamo_model';
import {removeUndefined} from '@shared/lib/type_utils';
import {DatalakeElementColumn} from '@shared/model/datalake';

import {Select} from '@shared-frontend/components/core/select';
import {Spacing} from '@shared-frontend/components/core/spacing';

import {useElements} from '@src/components/demo/datalake/demo_store';
import {elementItemToString} from '@src/components/demo/datalake/toolbox/element_type';
import {Label} from '@src/components/demo/datalake/toolbox/forms/common/form';

export type TableFieldChangeHandler = (
  tableId: DatalakeElementItemId | undefined,
  tableFieldIndexes: (number | undefined)[]
) => void;

interface TableAndFieldSelectorProps {
  tableLabel: string;
  tableId?: DatalakeElementItemId;
  fields: {
    label: string;
    index?: number;
  }[];
  filterColumn?: (v: DatalakeElementColumn) => boolean;
  onChange: TableFieldChangeHandler;
}

export function columnIs(type: DatalakeOutputColumnType): (v: DatalakeElementColumn) => boolean {
  return (v: DatalakeElementColumn): boolean => v.type === type;
}

export const TableAndFieldSelector: FC<TableAndFieldSelectorProps> = props => {
  const {tableLabel, tableId, fields, filterColumn = () => true, onChange} = props;
  const items = useElements();
  const elements = Object.values(items);

  const [outputs, setOutputs] = useState<DatalakeElementColumn[] | undefined>();

  const onSourceIdChange = useCallback(
    (newSourceId: DatalakeElementItemId | undefined) => {
      setOutputs(newSourceId === undefined ? undefined : items[newSourceId]?.outputs.columns ?? []);
      onChange(
        newSourceId,
        fields.map(() => undefined)
      );
    },
    [fields, items, onChange]
  );
  useEffect(
    () => setOutputs(tableId === undefined ? undefined : items[tableId]?.outputs.columns ?? []),
    [items, tableId]
  );

  const onFieldChange = useCallback(
    (newField: number | undefined, el: HTMLSelectElement) => {
      const index = parseFloat(el.getAttribute('data-index') ?? '');
      const newFields = [
        ...fields.slice(0, index).map(f => f.index),
        newField,
        ...fields.slice(index + 1).map(f => f.index),
      ];
      onChange(tableId, newFields);
    },
    [fields, onChange, tableId]
  );

  return (
    <>
      <Label>{tableLabel}</Label>
      <Select<DatalakeElementItemId | undefined>
        placeholder={'Sélectionnez une table'}
        values={elements
          .filter(el => el.params.group !== DatalakeElementTypeGroup.Output)
          .map(el => ({value: el.elementId, label: elementItemToString(el)}))}
        value={tableId}
        syncState={onSourceIdChange}
      />
      <Spacing height={8} />
      {fields.map((field, i) => (
        <Fragment key={i}>
          <Label>{field.label}</Label>
          <Select<number | undefined>
            placeholder={
              outputs === undefined ? 'Aucune table sélectionnée' : 'Sélectionnez un champ'
            }
            disabled={outputs === undefined}
            values={removeUndefined(
              (outputs ?? []).map((o, i) =>
                filterColumn(o) ? {value: i, label: o.name} : undefined
              )
            )}
            value={field.index}
            data-index={i}
            syncState={onFieldChange}
          />
          <Spacing height={8} />
        </Fragment>
      ))}
    </>
  );
};
TableAndFieldSelector.displayName = 'TableAndFieldSelector';
